import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DataTable from "react-data-table-component";
import AddPackagesForm from "./AddPackagesForm";
import AlertBox from "components/AlertBox/AlertBox";
import CountryDropdown from "components/Common/CountryDropdown";
import PointsForm from "./PointsForm";
import Search from "components/Search/Search";
import StandardPackagesDropdown from "./StandardPackagesDrodown";
import Status from "components/Common/Status";
import TestimonialsForm from "./TestimonialsForm";
import WebsiteCategoriesDropdown from "./WebsiteCategoriesDrodown";
import FAQForm from "components/Common/FAQForm";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import H2TagsForm from "components/Common/H2TagsForm";
import moment from "moment";

import internalCategoryOptions from "utils/InterenalCategory";

import SelectSecondaryCategory from "components/Common/SelectSecondaryCategory";


import RichTextEditor from "react-rte";


import OtherImages from "pages/Services/OtherImages";
const BASE_URL_PACKAGES_CMS_LIST = "list-cms-packages/";
const BASE_URL_PACKAGES_CMS_CREATE = "create-package-cms/";
const BASE_URL_PACKAGES_CUSTOM = "custom/packages/";
const BASE_URL_PACKAGES_BLOOD_WORK = "subscription-packages/";
const BASE_URL_PACKAGES_BLOOD_WORK_V2 = "/bloods/packages/";
const BASE_URL_WEBSITE_CATEGORIES = "web-category/";
const BASE_URL_COUNTRIES = "countries/";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const STATUS_OPTIONS = [
  {
    key: true,
    value: true,
    text: "Active",
  },
  {
    key: false,
    value: false,
    text: "Inactive",
  },
];

class PackagesCMS extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      packages: [],
      countries: [],
      customPackages: [],
      bloodBiomarkerPackages: [],
      websiteCategories: [],
      rankOptions: [],
      errors: {},
      editables: this.createEmptyEditables(),
      loadError: null,
      searchTerm: null,
      selectedCountry: null,
      selectedPackage: this.createEmptyPackage(),
      showModal: false,
      keyActiveTab: "key_details_form",
      minRank: 1,
      maxRank: 0,
      internal_category:"None",
      isGenerate:false,
      
      secondaryCategoryOptions:[],
      newSection:false,
      promotionalTextEnglishValue:RichTextEditor.createEmptyValue(),
      promotional_text_arabic:RichTextEditor.createEmptyValue(),
      key_benefits_english:RichTextEditor.createEmptyValue(),
      key_benefits_arabic:RichTextEditor.createEmptyValue(),
      ingredients_english:RichTextEditor.createEmptyValue(),
      ingredients_arabic:RichTextEditor.createEmptyValue(),
      disclaimers_english:RichTextEditor.createEmptyValue(),
      disclaimers_arabic:RichTextEditor.createEmptyValue()
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Name",
        selector: "name",
        wrap: true,
        sortable: true,
      },
      {
        name: "Website Category",
        selector: "website_category",
        wrap: true,
        sortable: true,
      },
      {
        name:"Secondary Category",
        selector:"secondary_categories_name",
        wrap: true,
        sortable: true,
        format:(row)=> this.renderSecondCategories(row)
      },
      {
        name: "Package Type",
        selector: "package_type",
        wrap: true,
        cell: (row) => this.renderPackageType(row),
      },
      {
        name: "Standard Package Name",
        selector: "title",
        wrap: true,
        cell: (row) => this.renderStandardPackageName(row),
      },
      {
        name: "Rank",
        selector: "position_value",
        width: "100px",
        sortable: true,
      },
      {
        name: "Status",
        selector: "is_active",
        maxWidth: "150px",
        center: true,
        cell: (p) => <Status isActive={p.is_active} />,
        sortable: true,
      },
      {
        name: "View",
        center: true,
        minWidth: "170px",
        cell: (p) => this.renderViewButton(p.id),
      },
    ];
  }

  async componentDidMount() {
    try {
      this.showLoading();
      await Promise.all([this.fetchAclUser(), this.fetchCountries(), this.fetchWebsiteCategories()]);;
      await this.fetchPackages();
    } 
    catch(error){
      console.log("Error in fetching details", error.message);
    }
    finally {
      this.hideLoading();
    }
    
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.PACKAGES_CMS;
      const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
      const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
      const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedCountry } = this.state;
    if (selectedCountry && selectedCountry !== prevState.selectedCountry) {
      // this.setState({ isCustomPackagesFetched: false, isBloodPackagesFetched: false }, () => {
      //   this.fetchCustomPackages(selectedCountry.id);
      //   this.fetchBloodworkPackages(selectedCountry.id);
      //   this.fetchWebsiteCategories(selectedCountry.id);
      // });
      this.fetchDependencies();
    }
  }

  handleMediaChange = (event) => {
    const selectedPackage = { ...this.state.selectedPackage };
    selectedPackage[event.target.name] = event.target.value;
    
    this.setState({ selectedPackage });
    
  };

  fetchDependencies = async () => {
    const { selectedCountry } = this.state;
    if (!selectedCountry) {
      console.log("fetchDependencies:: Invalid country!", { selectedCountry });
      return;
    }

    try {
      this.showLoading();
      await Promise.all([
        this.fetchBloodworkPackagesV2(selectedCountry.id),
        this.fetchCustomPackages(selectedCountry.id),
      ]);
    } finally {
      this.hideLoading();
    }
  }

  fetchPackages = async () => {
    this.showLoading();
    try {
      const { data: packages = [] } = await API.get(BASE_URL_PACKAGES_CMS_LIST);
      const ranks = packages.filter((it) => it.position_value).map((it) => it.position_value);
      const minRank = Math.min(...ranks) || 0;
      const maxRank = Math.max(...ranks) || 0;

      this.setState(
        {
          packages: packages
            ? packages.map((it) => {
              const websiteCategory = this.findWebsiteCategory(it.category);
              return {
                ...it,
                website_category: websiteCategory ? websiteCategory.name : "",
              };
            })
            : [],
          minRank,
          maxRank,
        },
        () => this.hideLoading()
      );
    } catch (error) {
      console.error(
        "Error on fetching packages from this URL: ",
        BASE_URL_PACKAGES_CMS_LIST
      );
      console.error("Error on fetching packages", error);

      this.hideLoading();
      this.showErrorNotification("Error on loading packages: ", error.message);
      this.setState({
        loadError: "Some error has occured. Please try again",
      });
    }
  };

  renderPackageType = (row) => {
    return (<>
      {row.standard_bloodpackage && <div>{row.standard_bloodpackage.package_type}</div>}
      {row.standard_custompackage && <div>{row.standard_custompackage.package_type}</div>}
    </>)
  }
  renderStandardPackageName = (row) => {
    return (<>
      {row.standard_bloodpackage && <div>{row.standard_bloodpackage.title}</div>}
      {row.standard_custompackage && <div>{row.standard_custompackage.title}</div>}

    </>)
  }

  renderSecondCategories=(row)=>{
    let {secondary_categories_name=[]}=row || {};
    const names=secondary_categories_name && secondary_categories_name.join(",")
    return names
  }
  fetchCountries = async () => {
    const isDefaultCountry = (country) => country.is_default;
    const countryMapper = (country) => ({
      id: country.id,
      country_name: country.country_name,
      currency: country.currency,
      is_active: country.is_active,
      is_default: country.is_default,
    });

    try {
      const { data: allCountries } = await API.get(BASE_URL_COUNTRIES);
      const countries = allCountries ? allCountries.map(countryMapper) : [];
      const defaultCountry = countries.find(isDefaultCountry);

      this.setState({
        countries,
        selectedCountry: defaultCountry,
      });
    } catch (error) {
      console.error(
        "Error on fetching countries from this URL: ",
        BASE_URL_COUNTRIES
      );
      console.error("Error on fetching countries", error);
    }
  };

  fetchWebsiteCategories = async () => {
    try {
      const url = BASE_URL_WEBSITE_CATEGORIES;
      const { data: websiteCategories = [] } = await API.get(url);
      this.setState({ websiteCategories });
    } catch (error) {
      console.error(
        "Error on fetching website categories from this URL: ",
        BASE_URL_WEBSITE_CATEGORIES
      );
      console.error("Error on fetching website categories", error);
      this.showErrorNotification("Error on fetching website categories!" + error);
    }
  };

  fetchCustomPackages = async (countryId) => {
    const url = countryId
      ? `${BASE_URL_PACKAGES_CUSTOM}?country=${countryId}`
      : BASE_URL_PACKAGES_CUSTOM;
    try {
      this.showLoading();
      const { data: customPackages = [] } = await PhoenixAPI.get(url);
      this.setState({
        customPackages: customPackages.map((it) => ({
          ...it,
          package_type: "custom",
        })),
      });
    } catch (error) {
      console.log("Error on fetching custom packages from this URL", url);
      console.log("Error on fetching custom packages", error);
      this.showErrorNotification("Error on fetching custom packages!" + error);
      return [];
    } finally {
      this.hideLoading();
    }
  };

  /*
  fetchBloodworkPackages = async (countryId) => {
    const url = countryId
      ? `${BASE_URL_PACKAGES_BLOOD_WORK}?country=${countryId}`
      : BASE_URL_PACKAGES_BLOOD_WORK;
    try {
      this.showLoading();
      const { data: bloodBiomarkerPackages = [] } = await API.get(url);
      this.setState({
        bloodBiomarkerPackages: bloodBiomarkerPackages.map((it) => ({
          ...it,
          package_type: "blood_biomarker",
        })),
      });
    } catch (error) {
      console.log("Error on fetching bloodwork packages from this URL", url);
      console.log("Error on fetching bloodwork packages", error);
      this.showErrorNotification("Error on fetching blood biomarker packages!" + error);
    } finally {
      this.hideLoading();
    }
  };
  */

  fetchBloodworkPackagesV2 = async (countryId) => {
    const url = `${BASE_URL_PACKAGES_BLOOD_WORK_V2}?country=${countryId}`;
    try {
      this.showLoading();
      const { data: bloodBiomarkerPackages = [] } = await PhoenixAPI.get(url);
      this.setState({
        bloodBiomarkerPackages: bloodBiomarkerPackages.map((it) => ({
          ...it,
          package_type: "blood_biomarker",
        })),
      });
    } catch (error) {
      console.log("Error on fetching bloodwork packages from this URL", url);
      console.log("Error on fetching bloodwork packages", error);
      this.showErrorNotification("Error on fetching blood biomarker packages!" + error);
    } finally {
      this.hideLoading();
    }
  };

  findCountry = (id) => {
    const { countries = [] } = this.state;
    return countries.find((country) => country.id === id);
  };

  findWebsiteCategory = (id) => {
    const { websiteCategories = [] } = this.state;
    return websiteCategories.find((it) => it.id === id);
  };

  findCustomPackage = (id) => {
    const { customPackages = [] } = this.state;
    return customPackages.find((it) => it.id === id);
  };

  findBloodBiomarkerPackage = (id) => {
    const { bloodBiomarkerPackages = [] } = this.state;
    return bloodBiomarkerPackages.find((it) => it.id === id);
  };

  handleCountrySelection = (country) =>
    this.setState({ selectedCountry: country });

  handleSearch = (queryString) => {
    const searchTerm= queryString ?? ""
    this.setState({
      searchApplied: true,
      // searchTerm: queryString ? queryString.trim() : "",
      searchTerm
    });
  };

  handleSearchClear = () => {
    this.setState({
      searchTerm: "",
      searchApplied: false,
    });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({
        searchTerm: "",
        searchApplied: false,
      });
    else this.setState({ searchExpanded: true });
  };

  handleAddNew = () => {
    const {websiteCategories, selectedCountry}= this.state;
    const websiteCategoriesForCountry = websiteCategories.filter((item)=>item.country===selectedCountry.id && item.is_active);
    const selectedPackage = {
      ...this.createEmptyPackage(),
      category: null,
      is_active: false,
      position_value : this.state.maxRank + 1,
      es_doc_id: Date.now(),
      internal_category:"None",
      editView:false
    };
    this.setState({ selectedPackage, secondaryCategoryOptions:websiteCategoriesForCountry}, () => this.showModal());
  };

  handleSave = () => {

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    console.log("check id", this.state.selectedPackage.es_doc_id);

    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { selectedPackage = {}, selectedCountry = {} ,
    promotionalTextEnglishValue,
     promotional_text_arabic,
     key_benefits_english,
     key_benefits_arabic,
     ingredients_english,
     ingredients_arabic,
     disclaimers_english,
     disclaimers_arabic
    } = this.state;

    console.log("key_benefits_english", {key_benefits_english,key_benefits_arabic});
    selectedPackage.promotional_section.promotional_text_english=promotionalTextEnglishValue.toString("html").trim().replace(/^<p><br><\/p>$/, '') ?? "";
    selectedPackage.promotional_section.promotional_text_arabic=promotional_text_arabic.toString("html").trim().replace(/^<p><br><\/p>$/, '') ??"";
    selectedPackage.promotional_section.key_benefits_english=key_benefits_english.toString("html").trim().replace(/^<p><br><\/p>$/, '') ??"";
    selectedPackage.promotional_section.key_benefits_arabic=key_benefits_arabic.toString("html").trim().replace(/^<p><br><\/p>$/, '')??"";
    selectedPackage.promotional_section.ingredients_english=ingredients_english.toString("html").trim().replace(/^<p><br><\/p>$/, '') ??"";
    selectedPackage.promotional_section.ingredients_arabic=ingredients_arabic.toString("html").trim().replace(/^<p><br><\/p>$/, '')??"";
    selectedPackage.promotional_section.disclaimers_english=disclaimers_english.toString("html").trim().replace(/^<p><br><\/p>$/, '') ??"";
    selectedPackage.promotional_section.disclaimers_arabic=disclaimers_arabic.toString("html").trim().replace(/^<p><br><\/p>$/, '') ??"";
   console.log("selected package:", selectedPackage);
this.setState({selectedPackage},()=>this.savePackageCms())
  
    
  };

  validate = () => {
    const errors = {};
    const {websiteCategoryNew,  selectedPackage}= this.state;
    const {website_category}= selectedPackage ||{}
    if(websiteCategoryNew==="Physiotherapy" || websiteCategoryNew==="IV Therapy" || website_category==="Physiotherapy" || website_category==="IV Therapy" || websiteCategoryNew==="IV Family Bundle" || website_category==="IV Family Bundle"){
      this.validateDetails(errors);
      // this.validateWhatsMeasured(errors);
      // this.validatePromotionalSection(errors);
      // this.validateRelatedSymptoms(errors);
      this.validateSEOMarketing(errors);
      // this.validateWhatsIncluded(errors);
      this.validateTags(errors);
    }
    else{
      this.validateDetails(errors);
      this.validateWhatsMeasured(errors);
      this.validatePromotionalSection(errors);
      this.validateRelatedSymptoms(errors);
      this.validateSEOMarketing(errors);
      this.validateWhatsIncluded(errors);
      this.validateTags(errors);
    }
   
    return Object.keys(errors).length === 0 ? null : errors;
  };

  validateDetails = (errors) => {
    const {
      name="",
      name_arabic="",
      standard_package={},
      standard_result_time="",
      standard_result_time_arabic="",
      package_label_eng="",
      package_label_ar="",
    } = this.state.selectedPackage || {};

    if (!name || name.trim() === "")
      errors.name = "Name (English) is a required field!";
    else if (name.length > 100)
      errors.name = "Name (English) can not contain more than 100 characters!";

    if (!name_arabic || name_arabic.trim() === "")
      errors.name_arabic = "Name (Arabic) is a required field!";
    else if (name_arabic.length > 100)
      errors.name_arabic =
        "Name (Arabic) can not contain more than 100 characters!";

    if (!standard_package || !standard_package.id)
      errors.standard_package =
        "Standard Package Associaion is a required field!";

    if (standard_result_time && standard_result_time.length > 100)
      errors.standard_result_time =
        "Result Time Standard (English) can not contain more than 100 characters!";

    if (standard_result_time_arabic && standard_result_time_arabic.length > 100)
      errors.standard_result_time_arabic =
        "Result Time Standard (Arabic) can not contain more than 100 characters!";
    if(package_label_eng && package_label_eng.length>150)
      errors.package_label_eng="Package Label English cannot contain more than 150 characters!";
      if(package_label_ar && package_label_ar.length>150)
      errors.package_label_ar="Package Label Arabic cannot contain more than 150 characters!";

  };

  validatePromotionalSection = (errors) => {
    const {selectedPackage={}}= this.state;
    const {promotional_section={}}= selectedPackage;
    const {
      heading ="",
      heading_arabic ="",
      paragraph="",
      paragraph_arabic="",
      promotional_points,
    } =promotional_section || {};

    if (!heading || heading.trim() === "")
      errors.promotional_section_heading =
        "Sub-heading (English) is a required field!";
    else if (heading.length > 200)
      errors.promotional_section_heading =
        "Sub-heading (English) can not contain more than 200 characters!";

    if (!heading_arabic || heading_arabic.trim() === "")
      errors.promotional_section_heading_arabic =
        "Sub-heading (Arabic) is a required field!";
    else if (heading_arabic.length > 200)
      errors.promotional_section_heading_arabic =
        "Sub-heading (Arabic) can not contain more than 200 characters!";

    if (!paragraph || paragraph.trim() === "")
      errors.promotional_section_paragraph =
        "Short Paragraph (English) is a required field!";
    else if (paragraph.length > 1000)
      errors.promotional_section_paragraph =
        "Short Paragraph (English) can not contain more than 1000 characters!";

    if (!paragraph_arabic || paragraph_arabic.trim() === "")
      errors.promotional_section_paragraph_arabic =
        "Short Paragraph (Arabic) is a required field!";
    else if (paragraph_arabic.length > 1000)
      errors.promotional_section_paragraph_arabic =
        "Short Paragraph (Arabic) can not contain more than 1000 characters!";

    // if (!promotional_points || promotional_points.length === 0)
    //   errors.promotional_points = "Points are required!";
  };

  validateWhatsMeasured = (errors) => {
    const {
      title ="",
      title_arabic ="",
      description ="",
      description_arabic="",
      link_attachment ="",
      link_attachment_arabic="",
    } = this.state.selectedPackage.whats_measured || {};

    if (!title || title.trim() === "")
      errors.whats_measured_title = "Title (English) is a required field!";
    else if (title.length > 200)
      errors.whats_measured_title =
        "Title (English) can not contain more than 200 characters!";

    if (!title_arabic || title_arabic.trim() === "")
      errors.whats_measured_title_arabic =
        "Title (Arabic) is a required field!";
    else if (title_arabic.length > 200)
      errors.whats_measured_title_arabic =
        "Title (Arabic) can not contain more than 200 characters!";

    if (!description || description.trim() === "")
      errors.whats_measured_description =
        "Long Description (English) is a required field!";
    else if (description.length > 1000)
      errors.whats_measured_description =
        "Long Description (English) can not contain more than 1000 characters!";

    if (!description_arabic || description_arabic.trim() === "")
      errors.whats_measured_description_arabic =
        "Long Description (Arabic) is a required field!";
    else if (description_arabic.length > 1000)
      errors.whats_measured_description_arabic =
        "Long Description (Arabic) can not contain more than 1000 characters!";

    if (link_attachment && link_attachment.length > 200)
      errors.whats_measured_link_attachment =
        "Link Attachment Name (English) can not take more than 200 characters!";

    if (link_attachment_arabic && link_attachment_arabic.length > 200)
      errors.whats_measured_link_attachment_arabic =
        "Link Attachment Name (Arabic) can not take more than 200 characters!";
  };

  validateRelatedSymptoms = (errors) => {
    const {
      title="",
      title_arabic="",
      description="",
      description_arabic="",
      relatedsymptoms_points,
    } = this.state.selectedPackage?.related_symptoms || {};

    // if (!title || title.trim() === "")
    //   errors.related_symptoms_title = "Title (English) is a required field!";
    // else if (title.length > 200)
    //   errors.related_symptoms_title =
    //     "Title (English) can not contain more than 200 characters!";

    if (title && title.length > 200)
      errors.related_symptoms_title =
        "Title (English) can not contain more than 200 characters!";

    // if (!title_arabic || title_arabic.trim() === "")
    //   errors.related_symptoms_title_arabic =
    //     "Title (Arabic) is a required field!";
    // else if (title_arabic.length > 200)
    //   errors.related_symptoms_title_arabic =
    //     "Title (Arabic) can not contain more than 200 characters!";

    if (title_arabic && title_arabic.length > 200)
      errors.related_symptoms_title_arabic =
        "Title (Arabic) can not contain more than 200 characters!";

    // if (!description || description.trim() === "")
    //   errors.related_symptoms_description =
    //     "Short Description (English) is a required field!";
    // else if (description.length > 1000)
    //   errors.related_symptoms_description =
    //     "Short Description (English) can not contain more than 1000 characters!";

    if (description && description.length > 1000)
      errors.related_symptoms_description =
        "Short Description (English) can not contain more than 1000 characters!";

    // if (!description_arabic || description_arabic.trim() === "")
    //   errors.related_symptoms_description_arabic =
    //     "Short Description (Arabic) is a required field!";
    // else if (description_arabic.length > 1000)
    //   errors.related_symptoms_description_arabic =
    //     "Short Description (Arabic) can not contain more than 1000 characters!";

    if (description_arabic && description_arabic.length > 1000)
      errors.related_symptoms_description_arabic =
        "Short Description (Arabic) can not contain more than 1000 characters!";

    // if (!relatedsymptoms_points || relatedsymptoms_points.length === 0)
    //   errors.related_symptoms_points = "Points are required!";
  };

  validateTags = (errors) => {
    const { tags = '' } = this.state.selectedPackage && this.state.selectedPackage.search_tags || {};
    if (tags && tags.length > 2000)
      errors.search_tags = "Tags can not contain more than 2000 characters!";
  }

  validateSEOMarketing = (errors) => {
    const { meta_title = '', meta_description = '', alt_image = '', keywords = '' } = this.state.selectedPackage && this.state.selectedPackage.seo_marketing || {};
    const { url_name =""} = this.state.selectedPackage || {}

    if (!url_name || url_name.trim() === "")
      errors.url_name = "URL is a required field!";
    else if (url_name.length > 100)
      errors.url_name =
        "URL name can not contain more than 100 characters!";

    // if (!meta_title)
    //   errors.seo_marketing_meta_title = "Title is a required field!";
    if (meta_title && meta_title.length > 100)
      errors.seo_marketing_meta_title =
        "Title can not contain more than 100 characters!";

    // if (!meta_description)
    //   errors.seo_marketing_meta_description = "Description is a required field!";
    if (meta_description && meta_description.length > 200)
      errors.seo_marketing_meta_description =
        "Description can not contain more than 200 characters!";

    // if (!alt_image)
    //   errors.seo_marketing_alt_image = "Alt-Image Tag is a required field!";
    if (alt_image && alt_image.length > 200)
      errors.seo_marketing_alt_image =
        "Alt-Image Tag can not contain more than 200 characters!";

    if (keywords && keywords.length > 500)
      errors.seo_marketing_keywords =
        "Keywords can not contain more than 500 characters!";
  };

  generateSearchTags=async()=>{
    let selectedpoints=[];
    let symptoms = [];
    let finalSymptoms = "";
    let title = "";
    let description = "";
  
    let replacesearchtags = "";
     const{selectedPackage}=this.state;
     const detailsArray = [
      selectedPackage?.name ?? '',
      selectedPackage?.promotional_section?.heading ?? '',
      selectedPackage?.promotional_section?.paragraph ?? '',
      selectedPackage?.whats_measured?.title ?? '',
      selectedPackage?.whats_measured?.description ?? ''
    ];
  
    // Join non-empty strings with commas
    const allDetails = detailsArray.filter(Boolean).join(', ');
    if (selectedPackage?.related_symptoms?.title !== undefined && selectedPackage?.related_symptoms?.title !== null && selectedPackage?.related_symptoms?.title !== "")
      title = selectedPackage?.related_symptoms?.title;

    if (selectedPackage?.related_symptoms?.description !== undefined && selectedPackage?.related_symptoms?.description !== null && selectedPackage?.related_symptoms?.description !== "")
      description = selectedPackage?.related_symptoms?.description;

    

    if (selectedPackage?.related_symptoms?.relatedsymptoms_points?.length > 0) {
      selectedpoints = selectedPackage?.related_symptoms?.relatedsymptoms_points?.map((text) => text.text_points).length > 0 ? selectedPackage?.related_symptoms?.relatedsymptoms_points?.map((text) => text.text_points) : [];
      symptoms = selectedpoints.map((text) => text?.replace(/(\r\n|\n)/g, ',')).flat();
     if (symptoms.length > 0) {
        finalSymptoms = symptoms.join(',');
      }
    }
    if (selectedPackage?.search_tags !== "" && selectedPackage?.search_tags !== null && selectedPackage?.search_tags !== undefined && selectedPackage?.search_tags !== "undefined"  && selectedPackage?.search_tags !== "null")
      replacesearchtags = selectedPackage?.search_tags?.replace(/(\r\n|\n)/g, ',');
      this.setState({isGenerate:true});
      
    const payload = [
      title|| null,
      description||null,
      finalSymptoms || null,
      replacesearchtags || null,
      allDetails || null
    ]
      .filter(item => item !== null && item !=="")
      .join(',');

      console.log("payload is",payload);
      try{
        const prevSearchTags = replacesearchtags ? replacesearchtags.split(',') : [];
        const response = await PhoenixAPI.post("generate-keywords",payload);
        const newSearchTags = response.data.message.split(',');
        const combinedSearchTags = Array.from(new Set([...prevSearchTags, ...newSearchTags]));
        const selectedPackage= {
          ...this.state.selectedPackage,
          search_tags:combinedSearchTags.join(','),
        };
        this.setState({ selectedPackage ,isGenerate:false});
        if(response.data.statusCode==200){
          this.showSuccessNotification("Successfully generated")
        }
      }
      catch(error){
        console.log("Error in generating search tags", error.message);
        this.showErrorNotification(
          "Error on fetching supplement by id! " + error.message ?? ""
        );
      }


  }


  validateWhatsIncluded = (errors) => {
    const { title="", title_arabic="", whatsincluded_points="" } =
      this.state.selectedPackage.whats_included || {};
    if (!title || title.trim() === "")
      errors.whats_included_title = "Title (English) is a required field!";
    else if (title.length > 200)
      errors.whats_included_title =
        "Title (English) can not contain more than 200 characters!";

    if (!title_arabic || title_arabic.trim() === "")
      errors.whats_included_title_arabic =
        "Title (Arabic) is a required field!";
    else if (title_arabic.length > 200)
      errors.whats_included_title_arabic =
        "Title (Arabic) can not contain more than 200 characters!";

    // if (!whatsincluded_points || whatsincluded_points.length === 0)
    //   errors.whats_included_points = "Points are required!";
  };

  savePackageCms = async() => {
    const { selectedPackage = {}, selectedCountry = {} ,
    promotional_text_english,
     promotional_text_arabic,
     key_benefits_english,
     key_benefits_arabic,
     ingredients_english,
     ingredients_arabic,
     disclaimers_english,
     disclaimers_arabic
    } = this.state;
    const {
      standard_package,
      promotional_section,
      whats_measured,
      seo_marketing={},
      related_symptoms,
      whats_included,
      testimonials,
      custompackage_variants,
      bloodpackage_variants,
      standard_result_time,
      standard_result_time_arabic,
      faq_cms,

      h2_tags,

      search_tags,
 
      es_doc_id,
      internal_category,
      secondary_categories
    } = selectedPackage;

    console.log("seo_marketing",seo_marketing)

    console.log("promotion selected", promotional_section);

    const datecheck = Date.now();

    if(seo_marketing && seo_marketing){
      seo_marketing.meta_title_ar=seo_marketing.meta_title_ar||seo_marketing.meta_title
      seo_marketing.meta_description_ar=seo_marketing.meta_description_ar||seo_marketing.meta_description
      seo_marketing.keywords_ar=seo_marketing.keywords_ar||seo_marketing.keywords
      seo_marketing.alt_image_ar=seo_marketing.alt_image_ar||seo_marketing.alt_image
    }


    const formData = new FormData();
    formData.append("name", selectedPackage.name);
    formData.append("name_arabic", selectedPackage.name_arabic);
    formData.append("is_active", selectedPackage.is_active);
    formData.append("position_value", selectedPackage.position_value);
    formData.append("category", selectedPackage.category || null);

    formData.append(
      "country",
      selectedPackage.id ? selectedPackage.country : selectedCountry.id
    );
    formData.append("package_label_eng",selectedPackage.package_label_eng || "");
    formData.append("package_label_ar",selectedPackage.package_label_ar || "");
    formData.append("url_name", selectedPackage.url_name || "");
    formData.append("search_tags", selectedPackage.search_tags);
    if(internal_category=="None"){
      formData.append("internal_category","");
    }else{
      formData.append("internal_category", internal_category);
    }
    if (selectedPackage.es_doc_id == null) {
      formData.append("es_doc_id", datecheck)
    }
    else {
      formData.append("es_doc_id", selectedPackage.es_doc_id);
    }
    if (standard_package.package_type === "custom") {
      formData.append("custom_packages", standard_package.id);
      formData.append("blood_packages", null);
    } else {
      formData.append("blood_packages", standard_package.id);
      formData.append("custom_packages", null);
    }

    formData.append("standard_result_time", standard_result_time || "");
    formData.append(
      "standard_result_time_arabic",
      standard_result_time_arabic || ""
    );

    for (let key in promotional_section) {
      if (key === "promotional_points")
        this.addPoints(
          formData,
          promotional_section[key],
          "promotional_section[promotional_points]"
        );
      else
        formData.append(
          `promotional_section[${key}]`,
          promotional_section[key]
        );
    }


    for (let key in whats_measured)
      formData.append(`whats_measured[${key}]`, whats_measured[key] || "");

    for (let key in seo_marketing)
      formData.append(`seo_marketing[${key}]`, seo_marketing[key] || "");


    for (let key in related_symptoms) {
      if (key === "relatedsymptoms_points")
        this.addPoints(
          formData,
          related_symptoms[key],
          "related_symptoms[relatedsymptoms_points]"
        );
      else formData.append(`related_symptoms[${key}]`, related_symptoms[key] ?? "");
    }

    for (let key in whats_included) {
      if (key === "whatsincluded_points")
        this.addPoints(
          formData,
          whats_included[key],
          "whats_included[whatsincluded_points]"
        );
      else formData.append(`whats_included[${key}]`, whats_included[key]);
    }

    if (testimonials && testimonials.length > 0) {
      testimonials.forEach((testimonial, index) => {
        for (let key in testimonial)
          formData.append(`testimonials[${index}][${key}]`, testimonial[key]);
      });
    }

    if (custompackage_variants) {
      custompackage_variants.forEach((variant, index) => {
        if (variant.id)
          formData.append(`custompackage_variants[${index}][id]`, variant.id);

        formData.append(
          `custompackage_variants[${index}][custom_package]`,
          variant.custom_package
        );
        formData.append(
          `custompackage_variants[${index}][result_time]`,
          variant.result_time
        );
        formData.append(
          `custompackage_variants[${index}][result_time_arabic]`,
          variant.result_time_arabic
        );
        formData.append(`custompackage_variants[${index}][position_value]`, variant.position_value);
        /*formData.append(`custompackage_variants[${index}][special_tag]`, variant.special_tag ?? "");
        formData.append(`custompackage_variants[${index}][special_tag_ar]`, variant.special_tag_ar ?? "");
        formData.append(`custompackage_variants[${index}][display_name]`, variant.display_name ?? "");
        formData.append(`custompackage_variants[${index}][display_name_ar]`, variant.display_name_ar ?? "");*/
      });
    }
    if (bloodpackage_variants) {
      bloodpackage_variants.forEach((variant, index) => {
        if (variant.id)
          formData.append(`bloodpackage_variants[${index}][id]`, variant.id);

        formData.append(
          `bloodpackage_variants[${index}][blood_package]`,
          variant.blood_package
        );
        formData.append(
          `bloodpackage_variants[${index}][result_time]`,
          variant.result_time
        );
        formData.append(
          `bloodpackage_variants[${index}][result_time_arabic]`,
          variant.result_time_arabic
        );
        formData.append(`bloodpackage_variants[${index}][position_value]`, variant.position_value);
       /* formData.append(`bloodpackage_variants[${index}][special_tag]`, variant.special_tag ?? "");
        formData.append(`bloodpackage_variants[${index}][special_tag_ar]`, variant.special_tag_ar ?? "");
        formData.append(`bloodpackage_variants[${index}][display_name]`, variant.display_name ?? "");
        formData.append(`bloodpackage_variants[${index}][display_name_ar]`, variant.display_name_ar ?? "");*/
      });


    }

    if (faq_cms) {
      console.log("faq cms",faq_cms);
      faq_cms.forEach((faq, index) => {
        if (faq.id)
          formData.append(`faq_cms[${index}][id]`, faq.id);

        formData.append(`faq_cms[${index}][question_english]`, faq?.question_english ?? "");
        formData.append(`faq_cms[${index}][question_arabic]`, faq?.question_arabic ?? "");
        formData.append(`faq_cms[${index}][answer_english]`, faq?.answer_english ?? "");
        formData.append(`faq_cms[${index}][answer_arabic]`, faq?.answer_arabic ?? "");
        formData.append(`faq_cms[${index}][position_value]`, faq?.position_value);
        formData.append(`faq_cms[${index}][is_active]`, faq?.is_active)
      })
    }
    console.log("h2_tags", h2_tags);
    if (h2_tags && h2_tags.length > 0) {
      // h2_tags.forEach((tag, index) => {
      //   if (tag.id)
      //     formData.append(`h2_tags[${index}][id]`, tag.id);

      //   formData.append(`h2_tags[${index}][header_title_english]`, tag?.header_title_english ?? "");
      //   formData.append(`h2_tags[${index}][header_title_arabic]`, tag?.header_title_arabic ?? "");
      //   formData.append(`h2_tags[${index}][header_description_english]`, tag?.header_description_english ?? "");
      //   formData.append(`h2_tags[${index}][header_description_arabic]`, tag?.header_description_arabic ?? "");
      //   formData.append(`h2_tags[${index}][is_active]`, tag?.is_active)
      // })
      h2_tags.forEach((it) => formData.append("h2_tags", JSON.stringify(it)))
    }

    if(secondary_categories && secondary_categories.length > 0){
      secondary_categories.forEach((it) => formData.append("secondary_categories", JSON.stringify(it)))
    }

    console.log("Form Data: ", ...formData);

    const apiOptions = {
      method: selectedPackage.id ? "patch" : "post",
      url: selectedPackage.id
        ? `${BASE_URL_PACKAGES_CMS_CREATE}${selectedPackage.id}/`
        : BASE_URL_PACKAGES_CMS_CREATE,
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };

    const notificationMessage = selectedPackage.id
      ? "Package successfully updated"
      : "Package successfully created";

    this.showLoading();

    try{
     const response= await  API.request(apiOptions)
        await  this.fetchPackages();
        this.showSuccessNotification(notificationMessage);
        this.saveSearchTagDocument(response, selectedPackage, selectedCountry, datecheck);
        this.hideModal();
    }

      catch(error)  {
        console.log("Error on saving CMS Package", error);
        // this.showErrorNotification(
        //   "Technical Error occurred while creating package. Please report the issue on #valeo-tech slack channel"
        // );
        this.showErrorNotification("Error in saving CMS Package", error.message);
      }
      finally{
       this.hideLoading()
      };

  };


  saveSearchTagDocument = (response, selectedPackage, selectedCountry, datecheck) => {

    console.log("inside doc update", response);
    let dateTime = new Date();
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1; // Months are 0-indexed
    const day = dateTime.getDate();
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const seconds = dateTime.getSeconds();
    console.log("Selected package", selectedPackage);
    let selectedpoints = [];
    let symptoms = [];
    let finalSymptoms = "";
    let title = "";
    let description = "";
    let titledescription = "";
    let replacesearchtags = "";
    if (selectedPackage?.related_symptoms?.title !== undefined && selectedPackage?.related_symptoms?.title !== null && selectedPackage?.related_symptoms?.title !== "")
      title = selectedPackage?.related_symptoms?.title?.replace(/(\r\n|\n)/g, ',');

    if (selectedPackage?.related_symptoms?.description !== undefined && selectedPackage?.related_symptoms?.description !== null && selectedPackage?.related_symptoms?.description !== "")
      description = selectedPackage?.related_symptoms?.description?.replace(/(\r\n|\n)/g, ',');

    titledescription = title + "," + description;

    if (selectedPackage?.related_symptoms?.relatedsymptoms_points?.length > 0) {
      selectedpoints = selectedPackage?.related_symptoms?.relatedsymptoms_points?.map((text) => text.text_points).length > 0 ? selectedPackage?.related_symptoms?.relatedsymptoms_points?.map((text) => text.text_points) : [];
     
      symptoms = selectedpoints.map((text) => text?.replace(/(\r\n|\n)/g, ',')).flat();


      if (symptoms.length > 0) {
        finalSymptoms = symptoms.join(',');
      }


    }
    if (selectedPackage?.search_tags !== "" && selectedPackage?.search_tags !== null && selectedPackage?.search_tags !== undefined && selectedPackage?.searchTags !== "undefined" && selectedPackage?.search_tags !== "null")
      replacesearchtags = selectedPackage?.search_tags?.replace(/(\r\n|\n)/g, ',');
    
    const cleanedHeading = selectedPackage?.promotional_section?.heading
  ?.replace(/(\r\n|\n)/g, '. ')
  .replace(/['"]/g, '')
  .trim();

const cleanedParagraph = selectedPackage?.promotional_section?.paragraph
  ?.replace(/(\r\n|\n)/g, '')
  .replace(/['"]/g, '')
  .trim();

const combinedString = cleanedHeading + ", " + cleanedParagraph;

const cleanedTitle = selectedPackage?.whats_measured?.title
  ?.replace(/(\r\n|\n)/g, '. ')
  .replace(/['"]/g, '');

const cleanedDescription = selectedPackage?.whats_measured?.description
  ?.replace(/(\r\n|\n)/g, ',')
  .replace(/['"]/g, '');

const combinedTitleDescriptionString = (cleanedTitle ? cleanedTitle : '') +","+ (cleanedDescription ? cleanedDescription : '');


    const dateform = moment(dateTime).format("DD/MM/YYYY");
    const payload = {
      "docId": selectedPackage?.es_doc_id == null ? datecheck : selectedPackage.es_doc_id,

      "itemId": selectedPackage?.standard_package?.id,
      "itemCategory": selectedPackage?.standard_package?.package_type == "custom" ? "Custom Package" : "Blood Package",

      "productNameH1": selectedPackage.name?.replace(/['"]/g, ''),
      "descriptionH2": combinedString,

      "whatsIncluded": combinedTitleDescriptionString,
      "relatedSymptoms": titledescription?.replace(/['"]/g, '') + " " +finalSymptoms?.replace(/['"]/g, ''),
      "dateAdded": dateform,
      "imageS3Url": selectedPackage?.standard_package?.image,
      "thumbnailS3Url": "",
      "price": 0.0,
      "seoUrl": selectedPackage?.url_name,
      "searchTags": replacesearchtags?.replace(/['"]/g, ''),
    }
    const countryFinal = selectedPackage.country ? selectedPackage?.country : selectedCountry.id;
    let url = "create/search-document/" + countryFinal;
    console.log("url is", url);
    try {
      // const response= selectedPackage?.es_doc_id?PhoenixAPI.patch(url,payload):PhoenixAPI.post(url,payload);
      const response = PhoenixAPI.post(url, payload);

    }
    catch (error) {
      console.log("Error on update or create  Package CMS search doc", error);
      //this.showErrorNotification("Some error occured in search document"+error); 
    }
  };

  addPoints = (formData, points, section) => {

    if (!formData || !points || points.length === 0) return;
     console.log("points",points);
    points.forEach((point, index) => {
      if (point.id) formData.append(`${section}[${index}][id]`, point.id);

      if (point.image && typeof point.image !== "string") {
        formData.append(`${section}[${index}][image]`, point.image);
        formData.append(`${section}[${index}][image_s3]`, point.image);
      }

      formData.append(`${section}[${index}][text_points]`, point.text_points);
      formData.append(
        `${section}[${index}][text_points_arabic]`,
        point.text_points_arabic
      );
      formData.append(`${section}[${index}][position_value]`, point.rank);
    });
  };

  addVariants = (formData, variants, section) => {
    variants.forEach((variant, index) => {
      if (variant.id) formData.append(`${section}[${index}][id]`, variant.id);
      formData.append(`${section}[${index}][id]`, variant.id);
      formData.append(`${section}[${index}][id]`, variant.id);
      for (let key in variant)
        formData.append(
          `custompackage_variants[${index}][${key}]`,
          variant[key]
        );
    });
  };

  handleViewPackage = (id) => {
    const packages = this.state.packages || [];
    const selectedPackage = packages.find((it) => it.id === id);
    const {websiteCategories, selectedCountry} = this.state;
    const websiteCategoriesForCountry = websiteCategories.filter((item)=>item.country===selectedCountry.id && item.is_active);
    const packageCategoryId= selectedPackage?.category ?? null;
    const filteredWebsiteCategories=websiteCategoriesForCountry.filter((item)=>item.id !== packageCategoryId);
    if (selectedPackage) {
      const country = this.findCountry(selectedPackage.country);
      const attachedStandardPackage = selectedPackage.standard_custompackage
        ? selectedPackage.standard_custompackage
        : selectedPackage.standard_bloodpackage;
      const standardPackage = selectedPackage.standard_custompackage
        ? this.findCustomPackage(attachedStandardPackage.id)
        : this.findBloodBiomarkerPackage(attachedStandardPackage.id);

      console.log("Standard Package: ", standardPackage);
       const {website_category, promotional_section={}}= selectedPackage || {};

       let newSection=false;
        if(website_category==="Physiotherapy" || website_category==="IV Therapy" || website_category==="IV Family Bundle"){
          newSection=true;
        }

        const promotionalTextEnglishValue = 
        RichTextEditor.createValueFromString(
          (promotional_section && promotional_section.promotional_text_english) ?? "",
          "html"
        );
        
        const promotional_text_arabic =
        RichTextEditor.createValueFromString(
          (promotional_section && promotional_section.promotional_text_arabic) ?? "",
          "html"
        );
        
        const key_benefits_english =
        RichTextEditor.createValueFromString(
          (promotional_section && promotional_section.key_benefits_english) ?? "",
          "html"
        );
        
        const key_benefits_arabic = 
        RichTextEditor.createValueFromString(
          (promotional_section && promotional_section.key_benefits_arabic) ?? "",
          "html"
        );
        const ingredients_english =
        RichTextEditor.createValueFromString(
          (promotional_section && promotional_section.ingredients_english) ?? "",
          "html"
        );
        const ingredients_arabic =
        RichTextEditor.createValueFromString(
          (promotional_section && promotional_section.ingredients_arabic) ?? "",
          "html"
        );
        const disclaimers_english =
        RichTextEditor.createValueFromString(
          (promotional_section && promotional_section.disclaimers_english) ?? "",
          "html"
        );
        const disclaimers_arabic =
        RichTextEditor.createValueFromString(
          (promotional_section && promotional_section.disclaimers_arabic) ?? "",
          "html"
        );
        

      this.setState(
        {
          selectedPackage: {
            ...selectedPackage,
            country_name: country ? country.country_name : "",
            currency: country ? country.currency : "",
            standard_result_time:
              attachedStandardPackage.standard_result_time || "",
            standard_result_time_arabic:
              attachedStandardPackage.standard_result_time_arabic || "",
            standard_package: {
              ...standardPackage,
              ...attachedStandardPackage,
              package_type: selectedPackage.standard_custompackage
                ? "custom"
                : "blood_biomarker",
            },
          },
          secondaryCategoryOptions:filteredWebsiteCategories,
          newSection,
          editView:true,
          promotionalTextEnglishValue,
          promotional_text_arabic,
          key_benefits_english,
          key_benefits_arabic,
          ingredients_english,
          ingredients_arabic,
          disclaimers_english,
          disclaimers_arabic
        },
        () => this.showModal()
      );
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const selectedPackage = { ...this.state.selectedPackage };
    selectedPackage[input.name] = input.value;
    console.log("input name",input.name,input.value);
    this.setState({ selectedPackage });
  };

  handleChangePromotionalSection = ({ currentTarget: input }) => {
    const promotionalName = input && input.name || '';
    const selectedPackage = { ...this.state.selectedPackage };
    if(selectedPackage && selectedPackage.promotional_section){
      selectedPackage['promotional_section'][promotionalName] = input.value;
    }
    else{
      selectedPackage['promotional_section'] = {
        [input.name]: input.value
      }
    }
    this.setState({ selectedPackage });
  };

  handleRichText=(value,name)=>{
    this.setState({[name]: value });
  }

  handleChangeWhatsMeasured = ({ currentTarget: input }) => {
    const selectedPackage = { ...this.state.selectedPackage };
    selectedPackage.whats_measured[input.name] = input.value;
    this.setState({ selectedPackage });
  };

  handleChangeRelatedSymptoms = ({ currentTarget: input }) => {
    const selectedPackage = { ...this.state.selectedPackage };
    if (!selectedPackage.related_symptoms) {
      selectedPackage.related_symptoms = {};
    }
    selectedPackage.related_symptoms[input.name] = input.value;
    this.setState({ selectedPackage });
  };

  handleChangeWhatsIncluded = ({ currentTarget: input }) => {
    const selectedPackage = { ...this.state.selectedPackage };
    selectedPackage.whats_included[input.name] = input.value;
    this.setState({ selectedPackage });
  };

  handleChangeSeoMarketing = ({ currentTarget: input }) => {
    const seoName = input && input.name || '';
    let selectedPackage = { ...this.state.selectedPackage };
    if (selectedPackage && selectedPackage.seo_marketing) {
      selectedPackage['seo_marketing'][seoName] = input.value;
    } else {
      selectedPackage['seo_marketing'] = {
        [input.name]: input.value
      }
    }
    this.setState({ selectedPackage });
  }


  handleVariantsChange = (variantsCustom, variantsBlood) => {
    let newSelectedPackage = { ...this.state.selectedPackage };
    delete newSelectedPackage.custompackage_variants;
    newSelectedPackage.custompackage_variants = variantsCustom;

    delete newSelectedPackage.bloodpackage_variants;
    newSelectedPackage.bloodpackage_variants = variantsBlood;

    this.setState({ selectedPackage: newSelectedPackage }, () => {
      console.log("Selected Package", this.state.selectedPackage);
    });
  };

  handleStandardPackageSelection = ({ value }) => {
    const [packageId, packageType] = value.split(":");
    const standardPackage =
      packageType === "custom"
        ? this.findCustomPackage(Number(packageId))
        : this.findBloodBiomarkerPackage(Number(packageId));

    this.setState({
      selectedPackage: {
        ...this.state.selectedPackage,
        standard_package:
          standardPackage.package_type === "blood_biomarker"
            ? {
              ...standardPackage,
              title: standardPackage.subscription_name,
              title_arabic: standardPackage.subscription_name_ar,
              image: standardPackage.image_s3_url || standardPackage.image || ""
            }
            : { ...standardPackage, image: standardPackage.infographic_image },
      },
    });
  };

  showModal = () => {
    const rankOptions = this.createRankOptions();
    this.setState({ rankOptions, showModal: true });
  };

  hideModal = () => {
    this.resetForm();
    this.setState({ showModal: false, newSection: false, editView: false });
  };

  resetForm = () => {
    this.setState({
      keyActiveTab: "key_details_form",
      errors: {},
      editables: this.createEmptyEditables(),
      selectedPackage: this.createEmptyPackage(),
      promotionalTextEnglishValue:RichTextEditor.createEmptyValue(),
      promotional_text_arabic:RichTextEditor.createEmptyValue(),
      key_benefits_english:RichTextEditor.createEmptyValue(),
      key_benefits_arabic:RichTextEditor.createEmptyValue(),
      ingredients_english:RichTextEditor.createEmptyValue(),
      ingredients_arabic:RichTextEditor.createEmptyValue(),
      disclaimers_english:RichTextEditor.createEmptyValue(),
      disclaimers_arabic:RichTextEditor.createEmptyValue()
    });
  };


  createRankOptions = () => {
    const { minRank, maxRank, packages = [], selectedPackage } = this.state;
    if (
      minRank === null ||
      minRank === undefined ||
      maxRank === null ||
      maxRank === undefined
    )
      return;

    const min = Math.min(1, minRank);
    const max = Math.max(maxRank, packages.length);
    const until = selectedPackage.id ? max : max + 1;
    let rankOptions = [];
    for (let i = min; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    return rankOptions;
  };

  renderViewButton = (packageId) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => this.handleViewPackage(packageId)}
      >
        View
      </button>
    </div>
  );

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && this.state.selectedPackage.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    if (field.includes(".")) {
      const [first, second] = field.split(".");
      console.log(first, second);
      editables[first][second] = true;
    } else {
      editables[field] = true;
    }
    this.setState({ editables });
  };

  getFilteredPackages = () => {
    const { packages, selectedCountry } = this.state;
    const packagesForCurrentCountry =
      packages && selectedCountry
        ? packages.filter((p) => p.country === selectedCountry.id)
        : [];
    return this.getSearchedPackages(packagesForCurrentCountry);
  };

  getSearchedPackages = (packages) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return packages;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return packages.filter((p) => {
      const { id, name, product_category, position_value } = p;
      return (
        (name && name.toLowerCase().indexOf(newValue) !== -1) ||
        (product_category &&
          product_category.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (position_value && position_value.toString() === newValue)
      );
    });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  createEmptyPackage = () => ({
    standard_package: {},
    promotional_section: {},
    whats_measured: {},
    related_symptoms: {},
    whats_included: {},
    seo_marketing: {},
    search_tags: "",
    es_doc_id: 0,
  });

  createEmptyEditables = () => ({
    promotional_section: {},
    whats_measured: {},
    related_symptoms: {},
    whats_related: {},
    seo_marketing: {},
    search_tags: "",
    es_doc_id: 0,
  });

  handleQuestionsUpdated = (questions = []) => {
    const selectedPackage = { ...this.state.selectedPackage, faq_cms: questions.map(this.faqToFaqCms) }
    this.setState({ selectedPackage });
  };

  handleTagsUpdated = (tags = []) => {
    const selectedPackage = { ...this.state.selectedPackage, h2_tags: tags.map(this.tagsToTagCms) }
    this.setState({ selectedPackage });
  }

  handlePointsUpdatedForPromotionalSection = (points = []) => {
    const {selectedPackage={}}= this.state
    const promotionalSection = {
      ...selectedPackage.promotional_section,
    };
    if(selectedPackage && selectedPackage.promotional_section){
      promotionalSection.promotional_points = points; 
    }
    else{
      promotionalSection["promotional_points"] = points;
    }
    

    const newSelectedPackage = {
      ...selectedPackage,
      promotional_section: promotionalSection,
    };
    this.setState({ selectedPackage :newSelectedPackage});
  };

  handlePointsUpdatedForRelatedSymptoms = (points = []) => {
    const relatedSymptoms = { ...this.state.selectedPackage.related_symptoms };
    relatedSymptoms.relatedsymptoms_points = points;

    const selectedPackage = {
      ...this.state.selectedPackage,
      related_symptoms: relatedSymptoms,
    };
    this.setState({ selectedPackage });
  };

  handlePointsUpdatedForWhatsIncluded = (points = []) => {
    const whatsIncluded = { ...this.state.selectedPackage.whats_included };
    whatsIncluded.whatsincluded_points = points;

    const selectedPackage = {
      ...this.state.selectedPackage,
      whats_included: whatsIncluded,
    };
    this.setState({ selectedPackage });
  };

  handleTestimonialsUpdated = (testimonials = []) => {
    const selectedPackage = { ...this.state.selectedPackage, testimonials };
    this.setState({ selectedPackage });
  };

  faqCmsToFaq = (faqCms) => faqCms && ({
    id: faqCms.id,
    questionEnglish: faqCms.question_english,
    questionArabic: faqCms.question_arabic,
    answerEnglish: faqCms.answer_english,
    answerArabic: faqCms.answer_arabic,
    status: faqCms.is_active ? "Active" : "Inactive",
    rank: faqCms.position_value,
  });

  faqToFaqCms = (it) => it && ({
    id: it.id,
    question_english: it.questionEnglish,
    question_arabic: it.questionArabic,
    answer_english: it.answerEnglish,
    answer_arabic: it.answerArabic,
    is_active: it.status === "Active" ? true : false,
    position_value: it.rank,
  });

  h2TagsCmsToH2Tags = (h2TagCms) => h2TagCms && ({
    id: h2TagCms?.id,
    headerTitleEnglish: h2TagCms?.header_title_english,
    headerTitleArabic: h2TagCms?.header_title_arabic,
    headerDescriptionEnglish: h2TagCms?.header_description_english ? h2TagCms.header_description_english : "",
    headerDescriptionArabic: h2TagCms?.header_description_arabic ? h2TagCms.header_description_arabic : "",
    isActive: h2TagCms.is_active ? true : false,
  })

  tagsToTagCms = (it) => it && ({
    id: it.id,
    header_title_english: it.headerTitleEnglish,
    header_title_arabic: it.headerTitleArabic,
    header_description_english: it?.headerDescriptionEnglish ? it.headerDescriptionEnglish : "",
    header_description_arabic: it?.headerDescriptionArabic ? it.headerDescriptionArabic : "",
    is_active: it.isActive === true ? true : false,

  })

  handleIvPhysioCategory=()=>{
    const {websiteCategoryNew}= this.state;
    let newSection=false;
    if(websiteCategoryNew==="Physiotherapy" || websiteCategoryNew==="IV Therapy" || websiteCategoryNew==="IV Family Bundle" ){
      newSection=true;
    }
    this.setState({newSection})
  }

  render() {
    const {
      countries,
      selectedCountry,
      websiteCategories,
      customPackages,
      bloodBiomarkerPackages,
      selectedPackage,
      editables,
      errors,
      searchTerm,
      isEditable,
      secondaryCategoryOptions,
      newSection,
      editView
    } = this.state;

    const { custompackage_variants = [], bloodpackage_variants = [] } =
      selectedPackage;

    const isNewPackage =
      selectedPackage.id === null || selectedPackage.id === undefined;
    const finalList = this.getFilteredPackages();

    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="name">Name (English)*</label>
            <div className="input-group">
              <input
                value={selectedPackage?.name ?? ""}
                onChange={this.handleChange}
                id="name"
                name="name"
                type="text"
                placeholder="Name in English"
                className={
                  "form-control py-2" + (errors.name ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.name}
              />
              {this.renderPencilIcon("name")}
            </div>
            <span className="help-block">{errors.name}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="name_arabic">Name (Arabic)*</label>
            <div className="input-group">
              <input
                value={selectedPackage && selectedPackage.name_arabic || ""}
                onChange={this.handleChange}
                id="name_arabic"
                name="name_arabic"
                type="text"
                placeholder="Name in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.name_arabic ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.name_arabic}
              />
              {this.renderPencilIcon("name_arabic")}
            </div>
            <span className="help-block">{errors.name_arabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="standard_package">
              Standard Package Association*
            </label>
            <div className="input-group">
              <StandardPackagesDropdown
                id="standard_package"
                name="standard_package"
                customPackages={customPackages || []}
                bloodBiomarkerPackages={bloodBiomarkerPackages || []}
                selectedPackage={
                  selectedPackage.standard_package &&
                    Object.keys(selectedPackage.standard_package).length !== 0
                    ? selectedPackage.standard_package
                    : null
                }
                onSelect={this.handleStandardPackageSelection}
                disabled={!isNewPackage && !editables.standard_package}
                placeholder="Select Standard Package..."
                className={
                  "form-control" + (errors.standard_package ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("standard_package")}
            </div>
            <span className="help-block">{errors.standard_package}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status*</label>
            <div className="input-group">
              <Dropdown
                value={selectedPackage.is_active}
                onChange={(event, value) =>
                  this.setState({
                    selectedPackage: {
                      ...selectedPackage,
                      is_active: value.value,
                    },
                  })
                }
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewPackage && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="category">Website Category</label>
            <div className="input-group">
              <WebsiteCategoriesDropdown
                websiteCategories={websiteCategories ? websiteCategories.filter(
                  (it) => it.country === selectedCountry?.id
                ) : []}
                selectedWebsiteCategory={selectedPackage.category}
                onSelect={(event) => {
                  this.setState({
                    selectedPackage: {
                      ...selectedPackage,
                      category: event.value,
                      
                    },
                    websiteCategoryNew:event.label
                  }, ()=>this.handleIvPhysioCategory());
                }}
                disabled={!isNewPackage && !editables.category}
                className={
                  "form-control" + (errors.category ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("category")}
            </div>
            <span className="help-block">{errors.category}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="rank">Rank*</label>
            <div className="input-group">
              <Dropdown
                value={selectedPackage.position_value}
                onChange={(event, value) =>
                  this.setState({
                    selectedPackage: { ...selectedPackage, position_value: value.value },
                  })
                }
                id="rank"
                name="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewPackage && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
        </div>
        {/* <div className="row">
          <div className="form-group col">
            <label htmlFor="status">Fast Track Package Association</label>
            <div className="input-group">
              <Dropdown
                value={selectedPackage.status === "Active"}
                onChange={(event, value) => {
                  const p = { ...selectedPackage };
                  p.status = value.value ? "Active" : "Inactive";
                  this.setState({ selectedPackage: p });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewPackage && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Urgent Package Association</label>
            <div className="input-group">
              <Dropdown
                value={selectedPackage.status === "Active"}
                onChange={(event, value) => {
                  const p = { ...selectedPackage };
                  p.status = value.value ? "Active" : "Inactive";
                  this.setState({ selectedPackage: p });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewPackage && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div> */}
       
       <div className="row">
         
         <div className="form-group col ">
           <label htmlFor="country">Country*</label>
           <div className="input-group mb-3">
           <input
             value={
               isNewPackage
                 ? selectedCountry && selectedCountry.country_name
                 : selectedPackage.country_name
             }
             type="text"
             id="country"
             name="country"
             readOnly
             className={
               "form-control py-2" + (errors.country ? " has-error" : "")
             }
           />
           </div>
           <span className="help-block">{errors.country}</span>
         
         
           <label htmlFor="package_label_english">Package Label (English)</label>
           <div className="input-group mb-3">
             <input
               value={selectedPackage.package_label_eng?selectedPackage.package_label_eng:""}
               onChange={this.handleChange}
               id="package_label_eng"
               name="package_label_eng"
               type="text"
               placeholder="Label in English"
               className={
                 "form-control py-2" + (errors.name ? " has-error" : "")
               }
               disabled={!isNewPackage && !editables.package_label_eng}
             />
             {this.renderPencilIcon("package_label_eng")}
           </div>
           <span className="help-block">{errors.package_label_eng}</span>
        
         
           <label htmlFor="package_label_arabic">Package Label (Arabic)</label>
           <div className="input-group">
             <input
               value={selectedPackage.package_label_ar?selectedPackage.package_label_ar : ""}
               onChange={this.handleChange}
               id="package_label_ar"
               name="package_label_ar"
               type="text"
               placeholder="Label in Arabic"
               className={
                 "form-control py-2 input-arabic" +
                 (errors.package_label_ar ? " has-error" : "")
               }
               disabled={!isNewPackage && !editables.package_label_ar}
             />
             {this.renderPencilIcon("package_label_ar")}
           </div>
           <span className="help-block">{errors.package_label_ar}</span>
         </div>
        

         <div className="form-group col">
           {selectedPackage?.standard_package?.image && (
             <>
               <label htmlFor="selected_package_image">
                 Selected Package Image
               </label>
               <div>
                 <img
                   id="selected_package_image"
                   name="selected_package_image"
                   src={selectedPackage.standard_package.image}
                   alt="Selected"
                   className="img-thumbnail mx-auto"
                   style={{
                     width: "100%",
                     maxHeight: "200px",
                     objectFit: "cover",
                   }}
                 />
               </div>
             </>
           )}
         </div>
       </div>
       
        
        <div className="row">
          <div className="form-group col">
            <label htmlFor="internal_category">Internal Category</label>
            <div className="input-group">
              <Dropdown
                value={selectedPackage.internal_category||"None"} 
                onChange={(event, value) =>
                  this.setState({
                    selectedPackage: { ...selectedPackage, internal_category: value.value },
                  })
                }
                id="internal_category"
                name="internal_category"
                placeholder="Internal Category"
                search
                selection
                options={internalCategoryOptions}
                disabled={!isNewPackage && !editables.internal_category}
                className={"form-control" + (errors.internal_category ? " has-error" : "")}
              />
              {this.renderPencilIcon("internal_category")}
            </div>
            <span className="help-block">{errors.internal_category}</span>
          </div>
          <div className="form-group col">
             <label>Select Secondary Category</label>
             <div className="input-group">
             <SelectSecondaryCategory
              categories={secondaryCategoryOptions ??[]}
              selectedCategories={selectedPackage?.secondary_categories??[]}
              onSelect={(values) => {
                this.setState({
                  selectedPackage: {
                    ...selectedPackage,
                    secondary_categories: values.map((it) => it.value),
                  },
                });
              }}
              isDisabled={!isNewPackage && !editables.secondary_categories}
                className={
                  "form-control" + (errors.secondary_categories ? " has-error" : "")
                }
                menuPlacement={"bottom"}
             />
               {this.renderPencilIcon("secondary_categories")}
             </div>
             <span className="help-block">{errors.secondary_categories}</span>
          </div>
        </div>
    
        
        
        
       
        



        <div className="row my-2">
          <div className="col separator" />
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="standard_result_time">
              Result Time Standard (English)
            </label>
            <div className="input-group">
              <input
                value={selectedPackage && selectedPackage.standard_result_time || ""}
                onChange={this.handleChange}
                id="standard_result_time"
                name="standard_result_time"
                type="text"
                placeholder="Result Time Standard in English"
                className={
                  "form-control py-2" +
                  (errors.standard_result_time ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.standard_result_time}
              />
              {this.renderPencilIcon("standard_result_time")}
            </div>
            <span className="help-block">{errors.standard_result_time}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="standard_result_time_arabic">
              Result Time Standard (Arabic)
            </label>
            <div className="input-group">
              <input
                value={selectedPackage && selectedPackage.standard_result_time_arabic || ""}
                onChange={this.handleChange}
                id="standard_result_time_arabic"
                name="standard_result_time_arabic"
                type="text"
                placeholder="Result Time Standard in Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.standard_result_time_arabic ? " has-error" : "")
                }
                disabled={
                  !isNewPackage && !editables.standard_result_time_arabic
                }
              />
              {this.renderPencilIcon("standard_result_time_arabic")}
            </div>
            <span className="help-block">
              {errors.standard_result_time_arabic}
            </span>
          </div>
        </div>
        <div className="row my-2">
          <div className="col separator" />
        </div>
        <div className="row">
          <div className="form-group col">
            <p style={{ fontWeight: "bolder", textAlign: "center" }}>
              Associated Packages - Add a Package is to allow for multiple types
              of same packages - Fast&nbsp;Track,&nbsp;Urgent,&nbsp;Bundles.
            </p>
          </div>
        </div>
        <div className="row">
          <AddPackagesForm
            standardPackage={selectedPackage.standard_package}
            customPackages={customPackages}
            customPackageVariants={custompackage_variants}
            bloodBiomarkerPackages={bloodBiomarkerPackages}
            bloodPackageVariants={bloodpackage_variants}
            onVariantsChange={this.handleVariantsChange}
          />
        </div>
      </div>
    );

    const promotionalSectionForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="title">Title (English)*</label>
            <input
              value={selectedPackage && selectedPackage.standard_package.title || ""}
              type="text"
              id="title"
              name="title"
              readOnly
              className="form-control py-2"
            />
          </div>
          <div className="form-group col">
            <label htmlFor="title">Title (Arabic)*</label>
            <input
              value={selectedPackage && selectedPackage.standard_package.title_arabic || ""}
              type="text"
              id="title_ar"
              name="title_ar"
              readOnly
              className="form-control input-arabic py-2"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="promotional_section_heading">
              Sub-heading (English)*
            </label>
            <div className="input-group">
              <input
                value={selectedPackage.promotional_section?selectedPackage.promotional_section.heading : ""}
                onChange={this.handleChangePromotionalSection}
                id="promotional_section_heading"
                name="heading"
                type="text"
                placeholder="Sub-heading in English"
                className={
                  "form-control py-2" +
                  (errors.promotional_section_heading ? " has-error" : "")
                }
                disabled={
                  !isNewPackage && !editables.promotional_section_heading
                }
              />
              {this.renderPencilIcon("promotional_section_heading")}
            </div>
            <span className="help-block">
              {errors.promotional_section_heading}
            </span>
          </div>
          <div className="form-group col">
            <label htmlFor="promotional_section_heading_arabic">
              Sub-heading (Arabic)*
            </label>
            <div className="input-group">
              <input
                value={selectedPackage.promotional_section ?selectedPackage.promotional_section.heading_arabic : ""}
                onChange={this.handleChangePromotionalSection}
                id="promotional_section_heading_arabic"
                name="heading_arabic"
                type="text"
                placeholder="Sub-heading in Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.promotional_section_heading_arabic
                    ? " has-error"
                    : "")
                }
                disabled={
                  !isNewPackage && !editables.promotional_section_heading_arabic
                }
              />
              {this.renderPencilIcon("promotional_section_heading_arabic")}
            </div>
            <span className="help-block">
              {errors.promotional_section_heading_arabic}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="promotional_section_paragraph">
              Short Paragraph (English)*
            </label>
            <div className="input-group">
              <textarea
                value={selectedPackage.promotional_section ?selectedPackage.promotional_section.paragraph : ""}
                onChange={this.handleChangePromotionalSection}
                id="promotional_section_paragraph"
                name="paragraph"
                placeholder="Short Paragraph in English"
                className={
                  "form-control py-2" +
                  (errors.promotional_section_paragraph ? " has-error" : "")
                }
                readOnly={
                  !isNewPackage && !editables.promotional_section_paragraph
                }
              ></textarea>
              {this.renderPencilIcon("promotional_section_paragraph")}
            </div>
            <span className="help-block">
              {errors.promotional_section_paragraph}
            </span>
          </div>
          <div className="form-group col">
            <label htmlFor="promotional_section_paragraph_arabic">
              Short Paragraph (Arabic)*
            </label>
            <div className="input-group">
              <textarea
                value={selectedPackage.promotional_section ? selectedPackage.promotional_section.paragraph_arabic : ""}
                onChange={this.handleChangePromotionalSection}
                id="promotional_section_paragraph_arabic"
                name="paragraph_arabic"
                placeholder="Short Paragraph in Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.promotional_section_paragraph_arabic
                    ? " has-error"
                    : "")
                }
                readOnly={
                  !isNewPackage &&
                  !editables.promotional_section_paragraph_arabic
                }
              ></textarea>
              {this.renderPencilIcon("promotional_section_paragraph_arabic")}
            </div>
            <span className="help-block">
              {errors.promotional_section_paragraph_arabic}
            </span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col separator">
            <span>Points</span>
          </div>
        </div>
        <div className="row">
          <span className="help-block mx-auto">
            {errors.promotional_points}
          </span>
          <PointsForm
            points={
              selectedPackage.promotional_section?selectedPackage.promotional_section.promotional_points : []
            }
            onPointsUpdated={this.handlePointsUpdatedForPromotionalSection}
            isEditable={isEditable}
          />
        </div>
      </div>
    );

    const whatsMeasuredForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="whats_measured.title">Title (English)*</label>
            <div className="input-group">
              <input
                value={selectedPackage && selectedPackage?.whats_measured?.title || ""}
                onChange={this.handleChangeWhatsMeasured}
                id="whats_measured.title"
                name="title"
                type="text"
                placeholder="Title in English"
                className={
                  "form-control py-2" +
                  (errors.whats_measured_title ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.whats_measured.title}
              />
              {this.renderPencilIcon("whats_measured.title")}
            </div>
            <span className="help-block">{errors.whats_measured_title}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="whats_measured.title_arabic">Title (Arabic)*</label>
            <div className="input-group">
              <input
                value={selectedPackage && selectedPackage?.whats_measured?.title_arabic || ""}
                onChange={this.handleChangeWhatsMeasured}
                id="whats_measured.title_arabic"
                name="title_arabic"
                type="text"
                placeholder="Title in Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.whats_measured_title_arabic ? " has-error" : "")
                }
                disabled={
                  !isNewPackage && !editables.whats_measured.title_arabic
                }
              />
              {this.renderPencilIcon("whats_measured.title_arabic")}
            </div>
            <span className="help-block">
              {errors.whats_measured_title_arabic}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="whats_measured.description">
              Long Description (English)*
            </label>
            <div className="input-group">
              <textarea
                value={selectedPackage?.whats_measured?.description || ""}
                onChange={this.handleChangeWhatsMeasured}
                id="whats_measured.description"
                name="description"
                placeholder="Long Description in English"
                readOnly={
                  !isNewPackage && !editables.whats_measured.description
                }
                className={
                  "form-control py-2" +
                  (errors.whats_measured_description ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("whats_measured.description")}
            </div>
            <span className="help-block">
              {errors.whats_measured_description}
            </span>
          </div>
          <div className="form-group col">
            <label htmlFor="whats_measured.description_arabic">
              Long Description (Arabic)*
            </label>
            <div className="input-group">
              <textarea
                value={selectedPackage?.whats_measured?.description_arabic || ""}
                onChange={this.handleChangeWhatsMeasured}
                id="whats_measured.description_arabic"
                name="description_arabic"
                placeholder="Long Description in Arabic"
                readOnly={
                  !isNewPackage && !editables.whats_measured.description_arabic
                }
                className={
                  "form-control input-arabic py-2" +
                  (errors.whats_measured_description_arabic ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("whats_measured.description_arabic")}
            </div>
            <span className="help-block">
              {errors.whats_measured_description_arabic}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="whats_measured.link_attachment">
              Link Attachment Name (English)
            </label>
            <div className="input-group">
              <input
                value={selectedPackage && selectedPackage?.whats_measured?.link_attachment || ""}
                onChange={this.handleChangeWhatsMeasured}
                id="whats_measured.link_attachment"
                name="link_attachment"
                type="text"
                placeholder="Link Attachment Name in English"
                className={
                  "form-control py-2" +
                  (errors.whats_measured_link_attachment ? " has-error" : "")
                }
                disabled={
                  !isNewPackage && !editables.whats_measured.link_attachment
                }
              />
              {this.renderPencilIcon("whats_measured.link_attachment")}
            </div>
            <span className="help-block">
              {errors.whats_measured_link_attachment}
            </span>
          </div>
          <div className="form-group col">
            <label htmlFor="whats_measured.link_attachment_arabic">
              Link Attachment Name (Arabic)
            </label>
            <div className="input-group">
              <input
                value={selectedPackage && selectedPackage?.whats_measured?.link_attachment_arabic || ""}
                onChange={this.handleChangeWhatsMeasured}
                id="whats_measured.link_attachment_arabic"
                name="link_attachment_arabic"
                type="text"
                placeholder="Link Attachment Name in Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.whats_measured_link_attachment_arabic
                    ? " has-error"
                    : "")
                }
                disabled={
                  !isNewPackage &&
                  !editables.whats_measured.link_attachment_arabic
                }
              />
              {this.renderPencilIcon("whats_measured.link_attachment_arabic")}
            </div>
            <span className="help-block">
              {errors.whats_measured_link_attachment_arabic}
            </span>
          </div>
        </div>
      </div>
    );

    const relatedSymptomsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="related_symptoms_title">Title (English)</label>
            <div className="input-group">
              <input
                value={selectedPackage && selectedPackage.related_symptoms?.title || ""}
                onChange={this.handleChangeRelatedSymptoms}
                id="related_symptoms_title"
                name="title"
                type="text"
                placeholder="Title in English"
                className={
                  "form-control py-2" +
                  (errors.related_symptoms_title ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.related_symptoms_title}
              />
              {this.renderPencilIcon("related_symptoms_title")}
            </div>
            <span className="help-block">{errors.related_symptoms_title}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="related_symptoms_title_arabic">
              Title (Arabic)
            </label>
            <div className="input-group">
              <input
                value={selectedPackage && selectedPackage.related_symptoms?.title_arabic || ""}
                onChange={this.handleChangeRelatedSymptoms}
                id="related_symptoms_title_arabic"
                name="title_arabic"
                type="text"
                placeholder="Title in Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.related_symptoms_title_arabic ? " has-error" : "")
                }
                disabled={
                  !isNewPackage && !editables.related_symptoms_title_arabic
                }
              />
              {this.renderPencilIcon("related_symptoms_title_arabic")}
            </div>
            <span className="help-block">
              {errors.related_symptoms_title_arabic}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="related_symptoms_description">
              Short Description (English)
            </label>
            <div className="input-group">
              <textarea
                value={selectedPackage.related_symptoms?.description}
                onChange={this.handleChangeRelatedSymptoms}
                id="related_symptoms_description"
                name="description"
                placeholder="Short Description in English"
                readOnly={
                  !isNewPackage && !editables.related_symptoms_description
                }
                className={
                  "form-control py-2" +
                  (errors.related_symptoms_description ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("related_symptoms_description")}
            </div>
            <span className="help-block">
              {errors.related_symptoms_description}
            </span>
          </div>
          <div className="form-group col">
            <label htmlFor="related_symptoms_description_arabic">
              Short Description (Arabic)
            </label>
            <div className="input-group">
              <textarea
                value={selectedPackage.related_symptoms?.description_arabic}
                onChange={this.handleChangeRelatedSymptoms}
                id="related_symptoms_description_arabic"
                name="description_arabic"
                placeholder="Short Description in Arabic"
                readOnly={
                  !isNewPackage &&
                  !editables.related_symptoms_description_arabic
                }
                className={
                  "form-control input-arabic py-2" +
                  (errors.related_symptoms_description_arabic
                    ? " has-error"
                    : "")
                }
              ></textarea>
              {this.renderPencilIcon("related_symptoms_description_arabic")}
            </div>
            <span className="help-block">
              {errors.related_symptoms_description_arabic}
            </span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col separator">
            <span>Points</span>
          </div>
        </div>
        <div className="row">
          <span className="help-block mx-auto">
            {errors.related_symptoms_points}
          </span>
          <PointsForm
            points={
              selectedPackage.related_symptoms?.relatedsymptoms_points || []
            }
            onPointsUpdated={this.handlePointsUpdatedForRelatedSymptoms}
            isEditable={isEditable}
          />
        </div>
      </div>
    );

    const whatsIncludedForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="whats_included_title">Title (English)*</label>
            <div className="input-group">
              <input
                value={selectedPackage && selectedPackage?.whats_included?.title || ""}
                onChange={this.handleChangeWhatsIncluded}
                id="whats_included_title"
                name="title"
                type="text"
                placeholder="Title in English"
                className={
                  "form-control py-2" +
                  (errors.whats_included_title ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.whats_included_title}
              />
              {this.renderPencilIcon("whats_included_title")}
            </div>
            <span className="help-block">{errors.whats_included_title}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="whats_included_title_arabic">Title (Arabic)*</label>
            <div className="input-group">
              <input
                value={selectedPackage && selectedPackage?.whats_included?.title_arabic || ""}
                onChange={this.handleChangeWhatsIncluded}
                id="whats_included_title_arabic"
                name="title_arabic"
                type="text"
                placeholder="Title in Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.whats_included_title_arabic ? " has-error" : "")
                }
                disabled={
                  !isNewPackage && !editables.whats_included_title_arabic
                }
              />
              {this.renderPencilIcon("whats_included_title_arabic")}
            </div>
            <span className="help-block">
              {errors.whats_included_title_arabic}
            </span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col separator">
            <span>Points</span>
          </div>
        </div>
        <div className="row">
          <span className="help-block mx-auto">
            {errors.whats_included_points}
          </span>
          <PointsForm
            points={selectedPackage?.whats_included?.whatsincluded_points || []}
            onPointsUpdated={this.handlePointsUpdatedForWhatsIncluded}
            isEditable={isEditable}
          />
        </div>
      </div>
    );

    const ivPhysioPromoForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="title">Title (English)*</label>
            <input
              value={selectedPackage && selectedPackage.standard_package.title || ""}
              type="text"
              id="title"
              name="title"
              readOnly
              className="form-control py-2"
            />
          </div>
          <div className="form-group col">
            <label htmlFor="title">Title (Arabic)*</label>
            <input
              value={selectedPackage && selectedPackage.standard_package.title_arabic || ""}
              type="text"
              id="title_ar"
              name="title_ar"
              readOnly
              className="form-control input-arabic py-2"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="promotional_section_heading">
              Sub-heading (English)*
            </label>
            <div className="input-group">
              <input
                value={selectedPackage.promotional_section ? selectedPackage.promotional_section.heading : ""}
                onChange={this.handleChangePromotionalSection}
                id="promotional_section_heading"
                name="heading"
                type="text"
                placeholder="Sub-heading in English"
                className={
                  "form-control py-2" +
                  (errors.promotional_section_heading ? " has-error" : "")
                }
                disabled={
                  !isNewPackage && !editables.promotional_section_heading
                }
              />
              {this.renderPencilIcon("promotional_section_heading")}
            </div>
            <span className="help-block">
              {errors.promotional_section_heading}
            </span>
          </div>
          <div className="form-group col">
            <label htmlFor="promotional_section_heading_arabic">
              Sub-heading (Arabic)*
            </label>
            <div className="input-group">
              <input
                value={selectedPackage.promotional_section ? selectedPackage.promotional_section.heading_arabic : ""}
                onChange={this.handleChangePromotionalSection}
                id="promotional_section_heading_arabic"
                name="heading_arabic"
                type="text"
                placeholder="Sub-heading in Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.promotional_section_heading_arabic
                    ? " has-error"
                    : "")
                }
                disabled={
                  !isNewPackage && !editables.promotional_section_heading_arabic
                }
              />
              {this.renderPencilIcon("promotional_section_heading_arabic")}
            </div>
            <span className="help-block">
              {errors.promotional_section_heading_arabic}
            </span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="session_time_english">
              Session Time in English
            </label>
            <div className="input-group">
              <input
                value={selectedPackage.promotional_section ? selectedPackage.promotional_section?.session_time_english : ""}
                onChange={this.handleChangePromotionalSection}
                id="session_time_english"
                name="session_time_english"
                type="text"
                placeholder="Session Time in English"
                className={
                  "form-control py-2" +
                  (errors.session_time_english ? " has-error" : "")
                }
                disabled={
                  !isNewPackage && !editables.session_time_english
                }
              />
              {this.renderPencilIcon("session_time_english")}
            </div>
            <span className="help-block">
              {errors.session_time_english}
            </span>
          </div>
          <div className="form-group col">
            <label htmlFor="promotional_section_heading_arabic">
              Session Time in Arabic
            </label>
            <div className="input-group">
              <input
                value={selectedPackage.promotional_section ? selectedPackage.promotional_section?.session_time_arabic : ""}
                onChange={this.handleChangePromotionalSection}
                id="session_time_arabic"
                name="session_time_arabic"
                type="text"
                placeholder="Session Time in Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.session_time_arabic
                    ? " has-error"
                    : "")
                }
                disabled={
                  !isNewPackage && !editables.session_time_arabic
                }
              />
              {this.renderPencilIcon("session_time_arabic")}
            </div>
            <span className="help-block">
              {errors.session_time_arabic}
            </span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col" style={{ maxWidth: '50%' }}>
            <label >
              Promotional Text in English
            </label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{ position: 'relative', flex: "1 1 auto", width: '1%', marginBottom: 0 }}
                editorStyle={{ height: '90px', overflow: 'hidden' }}
                value={this.state.promotionalTextEnglishValue ?? ""}
                onChange={(value) => this.handleRichText(value, "promotionalTextEnglishValue")}
                disabled={!isNewPackage && !editables.promotional_text_english}
              />
              {this.renderPencilIcon("promotional_text_english")}
            </div>
          </div>


          <div className="form-group col" style={{ maxWidth: '50%' }}>
            <label >
              Promotional Text in Arabic
            </label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{ position: 'relative', flex: "1 1 auto", width: '1%', marginBottom: 0 }}
                editorStyle={{ height: '90px', overflow: 'hidden' }}
                value={this.state.promotional_text_arabic ?? ""}
                onChange={(value) => this.handleRichText(value, "promotional_text_arabic")}
                disabled={!isNewPackage && !editables.promotional_text_arabic}
              />
              {this.renderPencilIcon("promotional_text_arabic")}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col" style={{ maxWidth: '50%' }}>
            <label >
              Key Benefits in English
            </label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{ position: 'relative', flex: "1 1 auto", width: '1%', marginBottom: 0 }}
                editorStyle={{ height: '90px', overflow: 'hidden' }}
                value={this.state.key_benefits_english ?? ""}
                onChange={(value) => this.handleRichText(value, "key_benefits_english")}
                disabled={!isNewPackage && !editables.key_benefits_english}
              />
              {this.renderPencilIcon("key_benefits_english")}
            </div>
          </div>


          <div className="form-group col" style={{ maxWidth: '50%' }}>
            <label >
              Key Benefits in Arabic
            </label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{ position: 'relative', flex: "1 1 auto", width: '1%', marginBottom: 0 }}
                editorStyle={{ height: '90px', overflow: 'hidden' }}
                value={this.state.key_benefits_arabic ?? ""}
                onChange={(value) => this.handleRichText(value, "key_benefits_arabic")}
                disabled={!isNewPackage && !editables.key_benefits_arabic}
              />
              {this.renderPencilIcon("key_benefits_arabic")}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col" style={{ maxWidth: '50%' }}>
            <label >
              Ingredients in English
            </label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{ position: 'relative', flex: "1 1 auto", width: '1%', marginBottom: 0 }}
                editorStyle={{ height: '90px', overflow: 'hidden' }}
                value={this.state.ingredients_english ?? ""}
                onChange={(value) => this.handleRichText(value, "ingredients_english")}
                disabled={!isNewPackage && !editables.ingredients_english}
              />
              {this.renderPencilIcon("ingredients_english")}
            </div>
          </div>


          <div className="form-group col" style={{ maxWidth: '50%' }}>
            <label >
              Ingredients in Arabic
            </label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{ position: 'relative', flex: "1 1 auto", width: '1%', marginBottom: 0 }}
                editorStyle={{ height: '90px', overflow: 'hidden' }}
                value={this.state.ingredients_arabic ?? ""}
                onChange={(value) => this.handleRichText(value, "ingredients_arabic")}
                disabled={!isNewPackage && !editables.ingredients_arabic}
              />
              {this.renderPencilIcon("ingredients_arabic")}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col" style={{ maxWidth: '50%' }}>
            <label >
              Disclaimers in English
            </label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{ position: 'relative', flex: "1 1 auto", width: '1%', marginBottom: 0 }}
                editorStyle={{ height: '90px', overflow: 'hidden' }}
                value={this.state.disclaimers_english ?? ""}
                onChange={(value) => this.handleRichText(value, "disclaimers_english")}
                disabled={!isNewPackage && !editables.disclaimers_english}
              />
              {this.renderPencilIcon("disclaimers_english")}
            </div>
          </div>


          <div className="form-group col" style={{ maxWidth: '50%' }}>
            <label >
              Disclaimers in Arabic
            </label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{ position: 'relative', flex: "1 1 auto", width: '1%', marginBottom: 0 }}
                editorStyle={{ height: '90px', overflow: 'hidden' }}
                value={this.state.disclaimers_arabic ?? ""}
                onChange={(value) => this.handleRichText(value, "disclaimers_arabic")}
                disabled={!isNewPackage && !editables.disclaimers_arabic}
              />
              {this.renderPencilIcon("disclaimers_arabic")}
            </div>
          </div>
        </div>
      </div>
    )
    const seoMarketingForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="url_name">URL Name*</label>
            <div className="input-group">
              <input
                value={selectedPackage && selectedPackage.url_name || ""}
                onChange={this.handleChange}
                id="url_name"
                name="url_name"
                type="text"
                placeholder="URL Name"
                className={
                  "form-control py-2" + (errors.url_name ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.url_name}
              />
              {this.renderPencilIcon("url_name")}
            </div>
            <span className="help-block">{errors.url_name}</span>
          </div>
          {/* <div className="form-group col">
            <label htmlFor="url_name_arabic">URL Name Arabic</label>
            <div className="input-group">
              <input
                value={selectedPackage && selectedPackage.url_name_arabic || ""}
                onChange={this.handleChange}
                id="url_name_arabic"
                name="url_name_arabic"
                type="text"
                placeholder="URL Name Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.url_name_arabic ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.url_name_arabic}
              />
              {this.renderPencilIcon("url_name_arabic")}
            </div>
            <span className="help-block">{errors.url_name_arabic}</span>
          </div> */}
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="seo_marketing.meta_title">Meta Title</label>
            <div className="input-group">
              <input
                value={selectedPackage.seo_marketing && selectedPackage.seo_marketing.meta_title || ""}
                onChange={this.handleChangeSeoMarketing}
                id="seo_marketing.meta_title"
                name="meta_title"
                type="text"
                placeholder="Meta Title"
                className={
                  "form-control py-2" + (errors.seo_marketing_meta_title ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.seo_marketing.meta_title}
              />
              {this.renderPencilIcon("seo_marketing.meta_title")}
            </div>
            <span className="help-block">{errors.seo_marketing_meta_title}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="seo_marketing.meta_title_ar">Meta Title Arabic</label>
            <div className="input-group">
              <input
                value={selectedPackage.seo_marketing && selectedPackage.seo_marketing.meta_title_ar || ""}
                onChange={this.handleChangeSeoMarketing}
                id="seo_marketing.meta_title_ar"
                name="meta_title_ar"
                type="text"
                placeholder="Meta Title Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.seo_marketing_meta_title_ar ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.seo_marketing.meta_title_ar}
              />
              {this.renderPencilIcon("seo_marketing.meta_title_ar")}
            </div>
            <span className="help-block">{errors.seo_marketing_meta_title_ar}</span>
          </div>
        </div>
        <div className="row">
        <div className="form-group col">
          <label htmlFor="seo_marketing_meta_description">Meta Description</label>
          <div className="input-group">
            <input
              value={selectedPackage.seo_marketing && selectedPackage.seo_marketing.meta_description || ""}
              onChange={this.handleChangeSeoMarketing}
              id="seo_marketing.meta_description"
              name="meta_description"
              type="text"
              placeholder="Meta Description"
              className={
                "form-control py-2" + (errors.seo_marketing_meta_description ? " has-error" : "")
              }
              disabled={!isNewPackage && !editables.seo_marketing.meta_description}
            />
            {this.renderPencilIcon("seo_marketing.meta_description")}
          </div>
          <span className="help-block">{errors.seo_marketing_meta_description}</span>
        </div>
        <div className="form-group col">
          <label htmlFor="seo_marketing_meta_description_ar">Meta Description Arabic</label>
          <div className="input-group">
            <input
              value={selectedPackage.seo_marketing && selectedPackage.seo_marketing.meta_description_ar || ""}
              onChange={this.handleChangeSeoMarketing}
              id="seo_marketing.meta_description_ar"
              name="meta_description_ar"
              type="text"
              placeholder="Meta Description Arabic"
              className={
                "form-control input-arabic py-2" + (errors.seo_marketing_meta_description_ar ? " has-error" : "")
              }
              disabled={!isNewPackage && !editables.seo_marketing.meta_description_ar}
            />
            {this.renderPencilIcon("seo_marketing.meta_description_ar")}
          </div>
          <span className="help-block">{errors.seo_marketing_meta_description_ar}</span>
        </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="seo_marketing.alt_image">Alt Image Tag</label>
            <div className="input-group">
              <input
                value={selectedPackage.seo_marketing && selectedPackage.seo_marketing.alt_image || ""}
                onChange={this.handleChangeSeoMarketing}
                id="seo_marketing.alt_image"
                name="alt_image"
                type="text"
                placeholder="Alt-Image Tag"
                className={
                  "form-control py-2" + (errors.seo_marketing_alt_image ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.seo_marketing.alt_image}
              />
              {this.renderPencilIcon("seo_marketing.alt_image")}
            </div>
            <span className="help-block">{errors.seo_marketing_alt_image}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="seo_marketing.alt_image_ar">Alt Image Tag Arabic</label>
            <div className="input-group">
              <input
                value={selectedPackage.seo_marketing && selectedPackage.seo_marketing.alt_image_ar || ""}
                onChange={this.handleChangeSeoMarketing}
                id="seo_marketing.alt_image_ar"
                name="alt_image_ar"
                type="text"
                placeholder="Alt-Image Tag Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.seo_marketing_alt_image_ar? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.seo_marketing.alt_image_ar}
              />
              {this.renderPencilIcon("seo_marketing.alt_image_ar")}
            </div>
            <span className="help-block">{errors.seo_marketing_alt_image_ar}</span>
          </div>
        </div>
      


        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="seo_marketing_keywords">Keywords</label>
            <div className="input-group">
              <textarea
                value={selectedPackage.seo_marketing && selectedPackage.seo_marketing.keywords || ""}
                onChange={this.handleChangeSeoMarketing}
                id="seo_marketing.keywords"
                name="keywords"
                type="text"
                placeholder="Keywords"
                className={
                  "form-control py-2" + (errors.seo_marketing_keywords ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.seo_marketing.keywords}
              />
              {this.renderPencilIcon("seo_marketing.keywords")}
            </div>
            <span className="help-block">{errors.seo_marketing_keywords}</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="seo_marketing_keywords_ar">Keywords Arabic</label>
            <div className="input-group">
              <textarea
                value={selectedPackage.seo_marketing && selectedPackage.seo_marketing.keywords_ar || ""}
                onChange={this.handleChangeSeoMarketing}
                id="seo_marketing.keywords_ar"
                name="keywords_ar"
                type="text"
                placeholder="Keywords Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.seo_marketing_keywords_ar? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.seo_marketing.keywords_ar}
              />
              {this.renderPencilIcon("seo_marketing.keywords_ar")}
            </div>
            <span className="help-block">{errors.seo_marketing_keywords_ar}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col separator">
            <span>H2 Tags</span>
          </div>
        </div>
        <div className="row">
          <H2TagsForm
            tags={selectedPackage?.h2_tags?.map(this.h2TagsCmsToH2Tags) || []}
            onTagUpdated={this.handleTagsUpdated}
            isEditable={isEditable}
          />
        </div>
      </div>
    );

    const testimonialsForm = (
      <div className="container mt-4">
        <TestimonialsForm
          testimonials={selectedPackage.testimonials || []}
          onTestimonialsUpdated={this.handleTestimonialsUpdated}
          isEditable={isEditable}
        />
      </div>
    );
    const formSearchEngine = (
      <div className="container mt-4">
        <div className="row">

          <div className="form-group col-6">
            <label htmlFor="tags">Tags</label>
            <div className="input-group">
              <textarea
                value={selectedPackage.search_tags !== "null" ? selectedPackage && selectedPackage.search_tags : ""}
                onChange={this.handleChange}
                id="tags"
                name="search_tags"
                placeholder="tags"
                className={
                  "form-control py-2" + (errors.search_tags ? " has-error" : "")
                }

                readOnly={
                  !isNewPackage && !editables.search_tags
                }
              ></textarea>
              {this.renderPencilIcon("search_tags")}
            </div>
            <span className="help-block">{errors.search_tags}</span>
          </div>
          <div className="form-group col">
                        <label></label><br />
                        <button
                            type="button"
                            onClick={this.generateSearchTags}
                            className={
                                "form-control btn btn-info mt-2 py-1" +
                                (this.state.searchTags ? "non-editable " : "editable ")
                            }
                            disabled={this.state.isGenerate}
                        >
                            Generate through AI
                        </button>
                        </div>

         

        </div>
      </div>


    );


    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNew}
                disabled={!isEditable}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={finalList}
            columns={this.columns}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={this.state.searchApplied ? false : true}
            title={
              this.state.searchApplied ? (
                <p className="table-header">
                  {"Found " + finalList.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox
                  message={this.state.loadError}
                  error={true}
                ></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={this.hideModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Package: </span>
                {selectedPackage?.name || ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSave}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              id="left-tabs-example"
              activeKey={this.state.keyActiveTab}
              onSelect={(key) => this.setState({ keyActiveTab: key })}
            // fill
            // justify
            >
              <Tab eventKey="key_details_form" title="Details">
                {detailsForm}
              </Tab>
              <Tab
                eventKey="key_promotional_section_form"
                title="Promotional Section"
                disabled={newSection}
              >
                {promotionalSectionForm}
              </Tab>
              <Tab eventKey="key_whats_measured_form" title="What's Measured"
               disabled={newSection}
              >
                {whatsMeasuredForm}
               
              </Tab>
              <Tab
                eventKey="key_related_symptoms_form"
                title="Related Symptoms"
                disabled={newSection}
              >
                {relatedSymptomsForm}
              </Tab>
              <Tab eventKey="key_whats_included_form" title="What's Included"
              disabled={newSection}
              >
                {whatsIncludedForm}
                
              </Tab>
              {(newSection) && <Tab  eventKey="key_iv&physion_promo_form" title="Iv & Physio Promotion Section">
                {ivPhysioPromoForm}
              </Tab>}
              <Tab eventKey="key_seo_marketing_form" title="SEO Marketing">
                {seoMarketingForm}
                
              </Tab>
              <Tab eventKey="key_testimonials_form" title="Testimonials">
                {testimonialsForm}
              </Tab>
              <Tab eventKey="key_faq_form" title="FAQ">
                <FAQForm
                  questions={selectedPackage?.faq_cms?.map(this.faqCmsToFaq) || []}
                  onQuestionUpdated={this.handleQuestionsUpdated}
                  isEditable={isEditable} />
              </Tab>
              <Tab
                eventKey="key_search_engine"
                title="Search Engine"
              >
                {formSearchEngine}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PackagesCMS);
